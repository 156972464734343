<script>
import { SuawMainContent, SuawDivider, SuawSection, SuawCarousel, SuawSubheaderTwo } from "@/components";
import WriteWithUs from "@/screens/resourcesIndex/components/WriteWithUs.vue";
import * as resourcesOrganizerDb from "@/screens/resourcesOrganizer/data.js";
import * as resourcesGeneralDb from "@/screens/resourcesGeneral/data.js";
import * as resourcesArticlesDb from "@/screens/resourcesArticles/data.js";

export default {
  name: "ResourceDiscovery",
  metaInfo: {
    title: "Resources - ",
    meta: [{ vmid: "description", name: "description", content: "Find helpful resources for writers and organizers, from writing tips to event organization guides." }]
  },
  components: {
    SuawMainContent,
    SuawDivider,
    SuawSection,
    SuawCarousel,
    SuawSubheaderTwo,
    WriteWithUs
  },
  data() {
    return {
      articles: resourcesArticlesDb.articles(),
      organizerResources: resourcesOrganizerDb.organizerResources(),
      generalResources: resourcesGeneralDb.generalResources()
    };
  },
  computed: {
    articleCards() {
      return this.articles;
    },
    organizerResourceCards() {
      return this.organizerResources.map(card => ({ ...card }));
    },
    generalResourceCards() {
      return this.generalResources.map(card => ({ ...card }));
    }
  },
  methods: {
    onClicked(val) {
      this.$router.push(val.route);
    }
  }
};
</script>

<template>
  <SuawMainContent :show-gradient="true">
    <SuawSubheaderTwo title="Resources" description="Discover helpful resources for writers and organizers, from writing tips to guides for planning impactful events." />
    <SuawSection>
      <SuawCarousel title="General Resources" :cards="generalResourceCards" :max-cards-per-page="3" @card-clicked="onClicked" />
    </SuawSection>
    <SuawSection>
      <SuawCarousel title="Organizer Toolkit" :cards="organizerResourceCards" :max-cards-per-page="3" @card-clicked="onClicked" />
    </SuawSection>
    <SuawSection>
      <SuawCarousel link="/resources/articles" title="Articles" :cards="articleCards" :max-cards-per-page="3" @card-clicked="onClicked" />
    </SuawSection>
    <SuawDivider />
    <WriteWithUs />
  </SuawMainContent>
</template>
