export function articles() {
  return [
    {
      id: "IWrote1000WordsFor30DaysAndHereIsWhatHappened",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      heading: "I Wrote 1,000 Words for 30 Days and Here’s What Happened",
      thumbnail: "https://media.graphassets.com/CcXNX5RZQuSk4yu4Mcvi",
      route: "/resources/articles/i-wrote-1000-words-for-30-days-and-here-is-what-happened",
      category: "Writing",
      preview: "Writing 1,000 words daily for 30 days boosts discipline, creativity, and productivity"
    },
    {
      id: "WhenShouldICallMyselfAWriter",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      heading: "When Should I Call Myself a Writer?",
      thumbnail: "https://media.graphassets.com/davdMHswQ1qH15LNrWiq",
      route: "/resources/articles/when-should-i-call-myself-a-writer",
      category: "Writing",
      preview: 'Embrace the "writer" title by consistently practicing your craft and overcoming self-doubt.'
    },
    {
      id: "WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      heading: "What Should I Write About? Five Ways To Find New Writing Ideas",
      thumbnail: "https://media.graphassets.com/xoOMWOhTtqyua7ofuORK",
      route: "/resources/articles/what-should-i-write-about-five-ways-to-find-new-writing-ideas",
      category: "Writing",
      preview: "Discover five effective strategies to generate fresh writing ideas and overcome writer's block."
    },
    {
      id: "FiveTraitsOfAnUnforgettableScene",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      featured: true,
      heading: "Five Traits of an Unforgettable Scene",
      preview: "Craft compelling scenes by developing characters, advancing the plot, and engaging readers.",
      thumbnail: "https://media.graphassets.com/6HMxuHQ1a3Uf1GywZf7Q",
      route: "/resources/articles/five-traits-of-an-unforgettable-scene",
      category: "Writing"
    },
    {
      id: "FiveWaysToCreateNewSettingsInYourWriting",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      heading: "Five Ways to Create New Settings in Your Writing",
      thumbnail: "https://media.graphassets.com/7T1zLZpZRayYBEDViK1y",
      route: "/resources/articles/five-ways-to-create-new-settings-in-your-writing",
      category: "Writing",
      preview: "Enhance your storytelling by drawing inspiration from novel sources."
    },
    {
      id: "SubmissionsTheHarshRealityAndHowToImproveYourOdds",
      authorId: "TheaPueschel",
      author: "Thea Pueschel",
      heading: "Submissions: The Harsh Reality and How to Improve Your Odds",
      thumbnail: "https://media.graphassets.com/UyrxZEkeR1WZgOrXzhZg",
      route: "/resources/articles/submissions-the-harsh-reality-and-how-to-improve-your-odds",
      category: "Publishing",
      preview: "Increase publication success with research, refining your work, and submitting frequently."
    },
    {
      id: "FiveStepsToImprovePacingInYourStory",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      heading: "Five Steps To Improve Pacing in Your Story",
      thumbnail: "https://media.graphassets.com/TUKtZIJFSAO2JnZJAygg",
      route: "/resources/articles/five-steps-to-improve-pacing-in-your-story",
      category: "Writing",
      preview: "Freshen up your story's pacing through experimentation and playing with perspective."
    },
    {
      id: "TheElementsOfStorytellingForNovelists",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      featured: true,
      heading: "The Elements of Storytelling for Novelists",
      thumbnail: "https://media.graphassets.com/zufGad7T8aAa4GCYdNkG",
      route: "/resources/articles/the-elements-of-storytelling-for-novelists",
      category: "Writing",
      preview: "Improve your storytelling by balancing plot, character development, setting, pacing, and tone."
    },
    {
      id: "HowToKeepYourWritingOnTrack",
      authorId: "RennieSaunders",
      author: "Rennie Saunders",
      heading: "How to Keep Your Writing on Track",
      thumbnail: "https://media.graphassets.com/7XiiEap0RZGVkFg1PuG9",
      route: "/resources/articles/how-to-keep-your-writing-on-track",
      category: "Writing",
      preview: "Maintain writing momentum by changing your environment and proactively managing distractions."
    },
    {
      id: "HowToWorkWithAnIllustrator",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      heading: "How to Work With an Illustrator",
      thumbnail: "https://media.graphassets.com/52bgJg6GQCq2aWL0gRAl",
      route: "/resources/articles/how-to-work-with-an-illustrator",
      category: "Publishing",
      preview: "Collaborate effectively with illustrators with clear communication and sharing your vision."
    },
    {
      id: "ShouldYouSelfPublishOrTraditionallyPublish",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      featured: true,
      heading: "Should You Self-Publish or Traditionally Publish?",
      thumbnail: "https://media.graphassets.com/f8dkHOoTu6Zg6SJDzHtt",
      route: "/resources/articles/should-you-self-publish-or-traditionally-publish",
      category: "Publishing",
      preview: "Decide between self-publishing and traditional publishing by asking yourself these questions."
    },
    {
      id: "HowToSelfEditInSixPainlessSteps",
      authorId: "AlisonKing",
      author: "Alison King",
      heading: "How to Self-Edit in Six Painless Steps",
      thumbnail: "https://media.graphassets.com/mRM9YE1SfGu4PcvIYPJe",
      route: "/resources/articles/how-to-self-edit-in-six-painless-steps",
      category: "Editing",
      preview: "Enhance your writing by identifying common errors and applying six self-editing techniques."
    },
    {
      id: "StayingCreativeWhileTheWorldIsBurning",
      authorId: "RennieSaunders",
      author: "Rennie Saunders",
      heading: "Staying Creative While the World is Burning",
      thumbnail: "https://media.graphassets.com/gsUuoWGuQ4WuK1trsOwm",
      route: "/resources/articles/staying-creative-while-the-world-is-burning",
      category: "Writing",
      preview: "Stay creative during challenging times by creating art out of your experience."
    },
    {
      id: "MakeWritingAHabitNotAChoice",
      authorId: "ClaireSplan",
      author: "Claire Splan",
      featured: true,
      heading: "Make Writing a Habit, Not a Choice",
      thumbnail: "https://media.graphassets.com/1niB9F6RiacrviXT6YL6",
      route: "/resources/articles/make-writing-a-habit-not-a-choice",
      category: "Writing",
      preview: "Transform writing into a habit by reducing decision fatigue and shaping your environment."
    },
    {
      id: "HowToFindALiteraryAgent",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      featured: true,
      heading: "How to Find a Literary Agent",
      thumbnail: "https://media.graphassets.com/10U99ylGTZWdvurzhWWH",
      route: "/resources/articles/how-to-find-a-literary-agent",
      category: "Publishing",
      preview: "Find a literary agent by using databases and reaching out strategically."
    },
    {
      id: "BetweenEditingAndSubmission",
      authorId: "JulieHoward",
      author: "Julie Howard",
      heading: "Between Editing and Submission",
      thumbnail: "https://media.graphassets.com/1EfTHruJQni4sltSfwFx",
      route: "/resources/articles/between-editing-and-submission",
      category: "Publishing",
      preview: "For traditional publishing, think about a marketing plan after polishing your manuscript."
    },
    {
      id: "HowToEditTheFirstDraftOfYourNovel",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      heading: "How to Edit the First Draft of Your Novel",
      thumbnail: "https://media.graphassets.com/ihAvZx2dThCPENdph3w0",
      route: "/resources/articles/how-to-edit-the-first-draft-of-your-novel",
      category: "Editing",
      preview: "Edit your novel's first draft by using a structured approach, including taking a break first."
    },
    {
      id: "HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite",
      authorId: "CristianAluas",
      author: "Cristian Aluas",
      heading: "How I Completed My First Novel With the Help of Shut Up & Write",
      thumbnail: "https://media.graphassets.com/yeIx6CnQtWdVmgjXor9n",
      route: "/resources/articles/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
      category: "Writing",
      preview: "Complete your writinng project by joining Shut Up & Write! for community and accountability."
    },
    {
      id: "FindingAndWorkingWithABetaReader",
      authorId: "AlisonKing",
      author: "Alison King",
      heading: "Finding and Working With a Beta Reader",
      thumbnail: "https://media.graphassets.com/CL2dY6vUQKaynJFNGbwZ",
      route: "/resources/articles/finding-and-working-with-a-beta-reader",
      category: "Editing",
      preview: "Get feedback on your writing by finding the right beta readers for your project."
    },
    {
      id: "HowToGetABookCoverYoureProudOf",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      heading: "How to Get a Book Cover You’re Proud Of",
      thumbnail: "https://media.graphassets.com/94sZSFMLQ6f0EpaLiqd7",
      route: "/resources/articles/how-to-get-a-book-cover-youre-proud-of",
      category: "Publishing",
      preview: "Book design involves more than the cover so make sure to work with qualified professionals."
    },
    {
      id: "TricksForWritingUnderPressure",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      heading: "Tricks for Writing Under Pressure",
      thumbnail: "https://media.graphassets.com/VTizxlsSReUVjl7Gk2TQ",
      route: "/resources/articles/tricks-for-writing-under-pressure",
      category: "Writing",
      preview: "Writing under pressure is easier if you make a plan and find a way to stay excited."
    },
    {
      id: "HowToGetFeedbackOnYourWriting",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      heading: "How to Get Feedback on Your Writing",
      thumbnail: "https://media.graphassets.com/M2fgScTfKf5AtMF5EfKQ",
      route: "/resources/articles/how-to-get-feedback-on-your-writing",
      category: "Editing",
      preview: "Get feedback from different categories of trusted readers based on the stage of your project."
    },
    {
      id: "FourEssentialStepsToDevelopingYourWritingPractice",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      heading: "Four Essential Steps to Developing Your Writing Practice",
      thumbnail: "https://media.graphassets.com/C6bDRIexSaO42vzDI54B",
      route: "/resources/articles/four-essential-steps-to-developing-your-writing-practice",
      category: "Writing",
      preview: "Writing consistently, including goal-setting and scheduling, is the key to finishing your draft."
    },
    {
      id: "SevenStrategiesToGetYouWritingAgain",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      featured: true,
      heading: "Seven Strategies to Get You Writing Again",
      thumbnail: "https://media.graphassets.com/zlNMrmZbQa6B7e50KAc4",
      route: "/resources/articles/seven-strategies-to-get-you-writing-again",
      category: "Writing",
      preview: "Use new ways to find inspiration when you're feeling blocked."
    },
    {
      id: "TheWritersGuideToCopyright",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      heading: "The Writer’s Guide to Copyright",
      thumbnail: "https://media.graphassets.com/euGEkbf8TqyvJM3tGdN6",
      route: "/resources/articles/the-writers-guide-to-copyright",
      category: "Publishing",
      preview: "Protect your writing by understanding copyright basics."
    },
    {
      id: "TenBestPracticesForWritingFromHome",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      heading: "10 Best Practices for Writing From Home",
      thumbnail: "https://media.graphassets.com/JxNUeqiWRmqJIRXi2u3g",
      route: "/resources/articles/10-best-practices-for-writing-from-home",
      category: "Writing",
      preview: "Boost productivity when writing from home by setting a dedicated workspace and establishing a routine."
    },
    {
      id: "TheWritersGuideToEditors",
      authorId: "AlisonKing",
      author: "Alison King",
      heading: "The Writer’s Guide to Editors",
      thumbnail: "https://media.graphassets.com/tDfqmZuRS12a28n9TKlY",
      route: "/resources/articles/the-writers-guide-to-editors",
      category: "Editing",
      preview: "Navigate the editing process by understanding different editor roles and selecting the right one for you."
    },
    {
      id: "CreatingYourWritingEnvironment",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      heading: "Creating Your Writing Environment",
      thumbnail: "https://media.graphassets.com/oYDdPAOQ2iQyHRWdXFFh",
      route: "/resources/articles/creating-your-writing-environment",
      category: "Writing",
      preview: "Create an optimal writing environment by setting boundaries and preparing your space."
    },
    {
      id: "FiveWaysToSilenceYourInnerCriticWhileDrafting",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      heading: "Five Ways to Silence Your Inner Critic While Drafting",
      thumbnail: "https://media.graphassets.com/Yl1jWzlXSZSpbKXAQGJW",
      route: "/resources/articles/five-ways-to-silence-your-inner-critic-while-drafting",
      category: "Writing",
      preview: "Overcome self-doubt during drafting by affirming your intentions and deploying novel tactics."
    },
    {
      id: "ShutUpAndWritesNanowrimoSurvivalGuide",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      heading: "Shut Up & Write!'s NaNoWriMo Survival Guide",
      thumbnail: "https://media.graphassets.com/8wUE3ccFS5ikuMmVlhoZ",
      route: "/resources/articles/shut-up-and-writes-nanowrimo-survival-guide",
      category: "Writing",
      preview: "Prepare for NaNoWriMo with strategies to manage your time to reach your 50,000-word goal."
    },
    {
      id: "FourEasyStepsToAWritingPlan",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      heading: "Four Easy Steps to a Writing Plan",
      thumbnail: "https://media.graphassets.com/7r6gjq5YSOp2o06tvRLG",
      route: "/resources/articles/4-easy-steps-to-a-writing-plan",
      category: "Writing",
      preview: "Develop an effective writing plan by setting clear goals and creating a realistic schedule."
    },
    {
      id: "FiveSimpleHabitsToBoostYourWriting",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      heading: "Five Simple Habits To Boost Your Writing",
      thumbnail: "https://media.graphassets.com/sY1pwJpRtuuhYk1YqNIz",
      route: "/resources/articles/5-simple-habits-to-boost-your-writing",
      category: "Writing",
      preview: "Boost your writing by observing the world, creating a ritual, and adding time to your practice."
    },
    {
      id: "ItsOkayToBeEditedReally",
      authorId: "JulieHoward",
      author: "Julie Howard",
      heading: "It’s Okay To Be Edited. Really.",
      thumbnail: "https://media.graphassets.com/XwWARFTZSxGAJaui6C2d",
      route: "/resources/articles/its-okay-to-be-edited-really",
      category: "Editing",
      preview: "Embrace the editing process because good feedback will help you improve your manuscript."
    },
    {
      id: "AttendingTheMuseOrYourWritingDiscipline",
      authorId: "RennieSaunders",
      author: "Rennie Saunders",
      heading: "“Attending the Muse,” or Your Writing Discipline",
      thumbnail: "https://media.graphassets.com/but9bLTNRxG94rux8XaK",
      route: "/resources/articles/attending-the-muse-or-your-writing-discipline",
      category: "Writing",
      preview: "Attend to your muse by showing up consistently to your writing practice."
    },
    {
      id: "FiveGeekyHobbiesForWritersThatArentReading",
      authorId: "KellieDoherty",
      author: "Kellie Doherty",
      heading: "Five Geeky Hobbies for Writers (That Aren’t Reading!)",
      thumbnail: "https://media.graphassets.com/wfUJKBoSVKVVmVYyyXuw",
      route: "/resources/articles/five-geeky-hobbies-for-writers-that-arent-reading",
      category: "Writing",
      preview: "Take a break from writing by exploring hobbies like gaming, coding, and attending conventions."
    },
    {
      id: "AfterTheFirstDraftProTipsForEditingYourWork",
      authorId: "ClaireSplan",
      author: "Claire Splan",
      heading: "After the First Draft, Pro Tips for Editing Your Work",
      thumbnail: "https://media.graphassets.com/34HINKLmRnKSJwvUvbV7",
      route: "/resources/articles/after-the-first-draft-pro-tips-for-editing-your-work",
      category: "Editing",
      preview: "Refine your manuscript by taking a break before editing, creating a problems list, and brainstorming solutions."
    },
    {
      id: "BeginningTipsForWritersWorkingStylesAndWip",
      authorId: "CindyPowers",
      author: "Cindy Powers",
      heading: "Beginning Tips for Writers: Working Styles and WIP",
      thumbnail: "https://media.graphassets.com/0yhuMFhCSYm0irv27Grp",
      route: "/resources/articles/beginning-tips-for-writers-working-styles-and-wip",
      category: "Writing",
      preview: "Learn about your unique working style when beginning your journey as a writer."
    },
    {
      id: "PerfectionismDistractsYouFromWritingPerfectly",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      heading: "Perfectionism Distracts You From Writing Perfectly",
      thumbnail: "https://media.graphassets.com/VMaTmI4KR5Glk6tHuqyS",
      route: "/resources/articles/perfectionism-distracts-you-from-writing-perfectly",
      category: "Writing",
      preview: "Overcome perfectionism to boost productivity and creativity in your writing."
    },
    {
      id: "ConquerProcrastinationAndGetWriting",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      heading: "Conquer Procrastination and Get Writing",
      thumbnail: "https://media.graphassets.com/rwpW4IkMQReKV9MY2Nmp",
      route: "/resources/articles/conquer-procrastination-and-get-writing",
      category: "Writing",
      preview: "Tackle procrastination by setting clear goals, creating a realistic plan, and finding support."
    },
    {
      id: "BeginningTipsForWritersTheBasics",
      authorId: "CindyPowers",
      author: "Cindy Powers",
      heading: "Beginning Tips for Writers: The Basics",
      thumbnail: "https://media.graphassets.com/lZxWaS8Ra6zcX4KcZUdQ",
      route: "/resources/articles/beginning-tips-for-writers-the-basics",
      category: "Writing",
      preview: "Build a strong writing routine by making time, reading more, and staying persistent."
    }
  ];
}

export function authors() {
  return [
    {
      id: "AlisonKing",
      name: "Alison King",
      initials: "AK",
      bio:
        "Shut Up & Write's Product Manager, Alison King, is also the team's in-house editor. Alison is a writer and lifelong music geek, and can be found in Berkeley, CA, or at @alison_king on Twitter.",
      articles: ["HowToMakeFriendsWithChatGpt", "HowToSelfEditInSixPainlessSteps", "FindingAndWorkingWithABetaReader", "TheWritersGuideToEditors"]
    },
    {
      id: "CatherineEllsworthon",
      name: "Catherine Ellsworthon",
      initials: "CE",
      bio: "",
      articles: ["FourEasyStepsToAWritingPlan", "FiveSimpleHabitsToBoostYourWriting", "PerfectionismDistractsYouFromWritingPerfectly", "ConquerProcrastinationAndGetWriting"]
    },
    {
      id: "CindyPowers",
      name: "Cindy Powers",
      initials: "CP",
      bio:
        "Writer of male/male romance stories and fan fiction. Find me Twitter and Tumblr hanging out with my friends, working on my stories, blogging, or immersed in learning something new. Be yourself, live your passion.",
      articles: ["BeginningTipsForWritersTheBasics", "BeginningTipsForWritersWorkingStylesAndWip"]
    },
    {
      id: "ClaireSplan",
      name: "Claire Splan",
      initials: "CS",
      bio:
        "Claire Splan writes fiction and nonfiction and runs a weekly Shut Up & Write session in Alameda. She has been a professional editor for what feels like a thousand years and has worked on literally hundreds of books on a wide range of topics. Her recently published book <a href='https://www.amazon.com/Not-So-Hot-Tomatoes-Growing-Delicious-Climates/dp/B093M54Z6G/ref=sr_1_1?dchild=1&keywords=not+so+hot+tomatoes&qid=1623429408&sr=8-1'>Not So Hot Tomatoes: Growing Tomatoes in Cooler Climates</a> is now available on Amazon.",
      articles: ["AfterTheFirstDraftProTipsForEditingYourWork", "MakeWritingAHabitNotAChoice"]
    },
    {
      id: "CristianAluas",
      name: "Cristian Aluas",
      initials: "CA",
      bio:
        "Shut Up & Write member Cristian S. Aluas is an artist and writer living in Manhattan. He's the author of a new book titled <a href='https://www.amazon.com/ITS-LIVING-Surviving-Freelancer-Professionals/dp/1709485345'>IT'S A LIVING: Surviving as a Freelancer in the 21st Century, The Ultimate Guide to Success for Artists and Creative Professionals</a>. Readers can follow Cristian on Instagram at @masterpieceartschool.",
      articles: ["HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite"]
    },
    {
      id: "JeremyThweatt",
      name: "Jeremy Thweatt",
      initials: "JT",
      bio:
        "Jeremy manages the content department and loves engaging our members through educational blog posts about the craft of writing, while continually reading about all things writing-related in order to distill what he learns into content for Shut Up & Write. He is currently co-authoring a science fiction novel with Rennie.",
      articles: [
        "HowToWorkWithAnIllustrator",
        "TricksForWritingUnderPressure",
        "FourEssentialStepsToDevelopingYourWritingPractice",
        "SevenStrategiesToGetYouWritingAgain",
        "CreatingYourWritingEnvironment",
        "ShutUpAndWritesNanowrimoSurvivalGuide"
      ]
    },
    {
      id: "JulieHoward",
      name: "Julie Howard",
      initials: "JH",
      bio:
        "Boise City Manager at Shut Up & Write! Julie Howard leads Shut Up & Write groups in Boise, Idaho. She is the author of <em>Crime and Paradise</em>, <em>Crime Times Two</em>, and <em>Spirited Quest</em>. She is currently on pins and needles waiting to see if her fourth book will be contracted by her publisher, The Wild Rose Press. More about her and her books are at www.juliemhoward.com or find her on Twitter at @_juliemhoward.",
      articles: ["BetweenEditingAndSubmission", "ItsOkayToBeEditedReally"]
    },
    {
      id: "KellieDoherty",
      name: "Kellie Doherty",
      initials: "KD",
      bio:
        "Kellie Doherty is from Eagle River, Alaska, though she now resides in Portland, Oregon. Living in Alaska for a good portion of her life gave her an appreciation for nature, a hankering for a good cup of tea or cider, and a passion for all things below zero. During those dark winter nights, she kept herself busy by writing stories and creating characters. After spending time in already created universes writing fan fiction, she decided to build her own. Her first book <a href='https://www.amazon.com/Finding-Hekate-Cicatrix-Duology-1/dp/1942976062'>Finding Hekate</a> was published by Desert Palm Press in April 2016, and she's currently working on the sequel titled Losing Hold. Her work has also been featured in Flight (Mischief Corner Books, 2016), 49 Writers Alaska Shorts, Pathos Literary Magazine, the Chugiak Eagle River Star, F Magazine, and Alaska Women Speak.",
      articles: ["FiveGeekyHobbiesForWritersThatArentReading"]
    },
    {
      id: "LiraSamanta",
      name: "Lira Samanta",
      initials: "LS",
      bio:
        "Lira Samanta is Data Analytics & Content Operations Manager at Shut Up & Write!. She writes poetry and literary & speculative fiction and is currently at work on her umpteenth novel.",
      articles: [
        "IWrote1000WordsFor30DaysAndHereIsWhatHappened",
        "WhenShouldICallMyselfAWriter",
        "WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas",
        "FiveTraitsOfAnUnforgettableScene",
        "FiveWaysToCreateNewSettingsInYourWriting",
        "FiveStepsToImprovePacingInYourStory",
        "TheElementsOfStorytellingForNovelists",
        "ShouldYouSelfPublishOrTraditionallyPublish",
        "HowToFindALiteraryAgent",
        "HowToEditTheFirstDraftOfYourNovel",
        "HowToGetABookCoverYoureProudOf",
        "HowToGetFeedbackOnYourWriting",
        "TheWritersGuideToCopyright",
        "TenBestPracticesForWritingFromHome",
        "FiveWaysToSilenceYourInnerCriticWhileDrafting"
      ]
    },
    {
      id: "RennieSaunders",
      name: "Rennie Saunders",
      initials: "RS",
      bio:
        "Rennie founded Shut Up & Write! in 2007 following a desire to meet fellow writers while working on a series of science fiction novels. Rennie spends an inordinate amount of time reading Wikipedia and Discover Magazine articles as research for his science fiction writing, practices Indonesian martial arts and cooks wholesome dinners for his family. His novella, <a href='https://www.amazon.com/gp/product/B01B7LKPSW/ref=dbs_a_def_rwt_hsch_vapi_tkin_p1_i0'>Pale Angel</a>, is available on Amazon and The Proteus Knife, a novel, will be released soon.",
      articles: ["HowToKeepYourWritingOnTrack", "StayingCreativeWhileTheWorldIsBurning", "AttendingTheMuseOrYourWritingDiscipline"]
    },
    {
      id: "TheaPueschel",
      name: "Thea Pueschel",
      initials: "TP",
      bio:
        "Thea Pueschel is a writer and multimedia artist in Southern California. Thea writes ESL readers to pay the bills and literary fiction to feed the soul. Thea won the TAEM Flash Fiction Summer 2020 Contest for the pandemic based satire “Safer at Home.”",
      articles: ["SubmissionsTheHarshRealityAndHowToImproveYourOdds"]
    }
  ];
}
