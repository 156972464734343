export function organizerResources() {
  return [
    {
      id: "OrganizerHandbook",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Organizer Handbook",
      thumbnail: "/img/resources/organizer/suaw-stock-image-coffee-book.png",
      route: "/resources/organizer/organizer-handbook",
      category: "Organizer",
      subcategory: "New Organizers",
      preview: "Comprehensive guide covering all aspects of organizing writing events, from basic principles to advanced community building."
    },
    {
      id: "AcademicOrganizers",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Academic Organizers",
      thumbnail: "/img/resources/organizer/suaw-stock-image-bookmark-pen.png",
      route: "/resources/organizer/academic-organizers",
      category: "Organizer",
      subcategory: "New Organizers",
      preview: "Guide for organizing writing events in academic settings, addressing unique needs of faculty, students, and researchers."
    },
    {
      id: "OrganizerFaq",
      authorId: "SuawTeam",
      // author: "Shut Up & Write! Team",
      // posted: "",
      heading: "Organizer FAQ",
      thumbnail: "/img/resources/organizer/suaw-stock-image-tablet-coffee.png",
      route: "/resources/organizer/organizer-faq",
      category: "Organizer",
      subcategory: "New Organizers",
      preview: "Answers to common questions about organizing events, managing communities, and handling various organizer responsibilities."
    },
    {
      id: "EventsToolkit",
      heading: "Events Toolkit",
      thumbnail: "/img/resources/organizer/suaw-stock-image-table-tent.png",
      route: "/resources/events-toolkit",
      category: "General",
      subcategory: "Policies & Downloads",
      preview: "Access our collection of writing resources, templates, and materials to enhance your sessions and organizational activities."
    }
  ];
}
