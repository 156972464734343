export function generalResources() {
  return [
    {
      id: "GetInvolved",
      heading: "Get Involved",
      thumbnail: "/img/resources/involved-hero.png",
      route: "/resources/get-involved",
      category: "General",
      subcategory: "Get Started",
      preview: "Discover ways to join our community, like attending writing sessions, organizing them, and more."
    },
    {
      id: "BecomeAnOrganizer",
      heading: "Become an Organizer",
      thumbnail: "/img/resources/become-an-organizer-hero.png",
      route: "/resources/become-an-organizer",
      category: "General",
      subcategory: "Get Started",
      preview: "Make an impact by becoming an organizer, leading writing sessions, and fostering a supportive community of writers."
    },
    {
      id: "Faq",
      heading: "FAQ",
      thumbnail: "/img/resources/organizer/suaw-stock-image-coffee-book.png",
      route: "/resources/faq",
      category: "General",
      subcategory: "About Us",
      preview: "Find answers about event participation, membership benefits, and ways to engage with our vibrant writing community."
    }
  ];
}
